<template>
    <b-modal 
    :visible="isDoctorFormSidebarActive"
    size="l"
    hide-footer
    
    @change="(val) => $emit('update:is-doctor-form-sidebar-active', val)"
    @shown="getData(doctorData)"
     @hidden="hide_form"

    >
    <template #modal-title>
      แพทย์  
    </template>
    <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="form-opd-primary"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
         <b-card no-body class="p-2">
            <validation-provider
            #default="validationContext"
            name="doctor"
            rules="required"
          >
            <b-form-group
              label="เลือกแพทย์"
              label-for="doctor"
            >
              <v-select
                :options="Doctors"
                v-model="Data.doctor_id"
                :clearable="false"
                :get-option-label="(option) => option.first_name +' '+ option.last_name"
                :reduce="val => val.id"
                >
                <template #option="{ first_name,last_name }">
                    {{ first_name }} {{ last_name }}
                
                    </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              
            </div>
        </b-card>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
 import {
    BForm, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink
 } from 'bootstrap-vue'
import {
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import vSelect from 'vue-select'
 import { useToast } from 'vue-toastification/composition'
export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BListGroup,
        BListGroupItem,
        BModal,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        BForm, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    model: {
     prop: 'isDoctorFormSidebarActive',
     event: 'update:is-doctor-form-sidebar-active',
   },
    props: {
    isDoctorFormSidebarActive: {
       type: Boolean,
       required: true,
     },
     Doctors:{
        type: Array
     },
    
     doctorData: {
       type: Object,
       required: false,
     },
   },
   setup(props, { emit }){
    const toast = useToast()
    const blankData = {
        id:'',
       doctor_id:''
    };
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = (data)=>{
    
        if(data){
           
           Data.value.id = data.id;
           Data.value.doctor_id = data.doctor_id;
        }
    }
    const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const onSubmit = async () => {
        //updatedoctorData
        const response = await store.dispatch('app-queue/updateDoctor', Data.value);
        toast({
            component: ToastificationContent,
            props: {
                title: "Update Doctor Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
            },
        })

        emit('update:is-doctor-form-sidebar-active', false)
        emit('refeshData')
       

    }
    const hide_form = ()=>{
        emit('update:is-doctor-form-sidebar-active', false)
    }
    const {
       refFormObserver,
       
      
       getValidationState,
       resetForm,
     } = formValidation(resetData)
    return {
        onSubmit,
        getData,
        Data,
        refFormObserver,
       getValidationState,
       resetForm,
       resetData,
      
       hide_form
    }

   }
}
</script>