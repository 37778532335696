<template>
    <b-modal 
    :visible="isRoomFormSidebarActive"
    size="l"
    hide-footer
    
    @change="(val) => $emit('update:is-room-form-sidebar-active', val)"
    @shown="getData(roomData)"
     @hidden="hide_form"

    >
    <template #modal-title>
      Room
    </template>
    <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="form-opd-primary"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
         <b-card no-body class="p-2">
            <validation-provider
                                    #default="validationContext"
                                    name="room_id"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <label>เลือกห้อง</label>
                                        <v-select
                                            :options="Rooms"
                                            v-model="Data.room_id"
                                            :get-option-label="(option) => option.title"
                                            :reduce="val => val.id"
                                            >
                                            <template #option="{ title }">
                                            {{ title }}
                                            <br />
                                            </template>
                                        </v-select>
                                        
                                    </b-form-group>
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                
            </validation-provider>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              
            </div>
        </b-card>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
 import {
    BForm, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink
 } from 'bootstrap-vue'
import {
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
  import vSelect from 'vue-select'
export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BListGroup,
        BListGroupItem,
        BModal,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        BForm, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    model: {
     prop: 'isRoomFormSidebarActive',
     event: 'update:is-room-form-sidebar-active',
   },
    props: {
    isRoomFormSidebarActive: {
       type: Boolean,
       required: true,
     },
     Rooms:{
        type:Array

     },
     roomData: {
       type: Object,
       required: false,
     },
   },
   setup(props, { emit }){
    const toast = useToast()
    const blankData = {
        id:'',
        room_id:''
    };
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = (data)=>{
    
        if(data){
           
           Data.value.id = data.id;
           Data.value.room_id = data.room_id;
        }
    }
    const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const onSubmit = async () => {
        //updateroomData
        const response = await store.dispatch('app-queue/updateRoom', Data.value);
        toast({
            component: ToastificationContent,
            props: {
                title: "Update Room Success",
                icon: 'AlertTriangleIcon',
                variant: 'success',
            },
        })

        emit('update:is-room-form-sidebar-active', false)
        emit('refeshData')
    }
    const hide_form = ()=>{
        emit('update:is-room-form-sidebar-active', false)
    }
    const {
       refFormObserver,
       
      
       getValidationState,
       resetForm,
     } = formValidation(resetData)
    return {
        onSubmit,
        getData,
        Data,
        refFormObserver,
       getValidationState,
       resetForm,
       resetData,
      
       hide_form
    }

   }
}
</script>